import React, { SVGProps } from 'react';

const Loading = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="96"
    height="96"
    viewBox="0 0 96 96"
    fill="none"
    {...props}
  >
    <rect width="96" height="96" rx="48" fill="#F9F7FE" />
    <path
      d="M48 40V38.21C48 37.76 47.46 37.54 47.15 37.86L44.35 40.65C44.15 40.85 44.15 41.16 44.35 41.36L47.14 44.15C47.46 44.46 48 44.24 48 43.79V42C51.31 42 54 44.69 54 48C54 48.79 53.85 49.56 53.56 50.25C53.41 50.61 53.52 51.02 53.79 51.29C54.3 51.8 55.16 51.62 55.43 50.95C55.8 50.04 56 49.04 56 48C56 43.58 52.42 40 48 40ZM48 54C44.69 54 42 51.31 42 48C42 47.21 42.15 46.44 42.44 45.75C42.59 45.39 42.48 44.98 42.21 44.71C41.7 44.2 40.84 44.38 40.57 45.05C40.2 45.96 40 46.96 40 48C40 52.42 43.58 56 48 56V57.79C48 58.24 48.54 58.46 48.85 58.14L51.64 55.35C51.84 55.15 51.84 54.84 51.64 54.64L48.85 51.85C48.54 51.54 48 51.76 48 52.21V54Z"
      fill="#7251EC"
    />
  </svg>
);

export default Loading;
