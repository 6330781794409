import {  Router } from '@reach/router';
import AccountEvents from 'components/AccountEvents';
import React from 'react';
const basepath = 'account/events';
function events() {
  return (
    <Router basepath={basepath}>
      <AccountEvents  basepath={basepath} path=":tab" />
      <AccountEvents default basepath={basepath}  />
    </Router>
  );
}

events.propTypes = {};

export default events;
