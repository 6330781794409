import React from 'react';
import UserDashboard from 'components/DashboardLayout/UserDashboard';
import emptyImage from '../assets/empty.png';
import { Loading } from 'components/icons';
import { useAuth0, User } from '@auth0/auth0-react';
import { useQuery } from '@apollo/client';
import { getEvents } from 'services/hasura';
import InfoCard from 'components/InfoCard';
import tw from 'twin.macro';

import { Box, Tab, Tabs } from 'grommet';
import { EventCard } from 'tutadoo-design-system/src/components';
import { navigate, RouteComponentProps } from '@reach/router';
import { EventsService, IEvent } from 'services/events';
import EventCardAnimation from 'components/EventCardAnimation';
import useToast from '../hooks/useToast';


const Container = tw.div`max-w-[1312px] mx-auto`;
const Button = tw.button`w-[184px] h-[48px] rounded-[100px] font-semibold`;

const EmptyEvents = () => (
  <div className="flex flex-col items-center justify-center">
    <img src={emptyImage} alt="no image" />
    <h3 className="font-semibold text-2xl leading-9 text-dark">There are no events yet</h3>
    <p style={{ color: '#3B3F49' }} className="text-base text-primary">
      It's time to explore extraordinary events with Tutadoo.
    </p>
    <Button className="bg-purple-800 text-white my-6">Browse all events</Button>
    <Button className="text-purple-800 py-0">Create an event</Button>
  </div>
);

const LoadingUI = ({ children }) => (
  <div className="flex flex-col items-center justify-center">
    <Loading />
    {children}
  </div>
);

//onst StyledTab = styled(Tab)``;

const Upcoming = ({ user }) => {
  const resp = useQuery(getEvents(user?.sub));

  if (resp.loading)
    return (
      <LoadingUI>
        <h2 className="font-semibold text-2xl leading-8">Fetching Upcoming Events</h2>
      </LoadingUI>
    );

  return (
    <div>
      {Array.isArray(resp?.data?.events) &&
        resp?.data?.events?.map((event: any) => <EventCard {...event} />)}
      {resp?.data.length < 1 && <EmptyEvents />}
    </div>
  );
};

const PastEvent = () => (
  <div>
    <EmptyEvents />
  </div>
);

const Wishlist = ({ user }: { user: User }) => {
  const { renderToast, toast } = useToast();
  const { data, loading } = useQuery<{ wishList: { event: IEvent }[] }>(
    EventsService.getWishList(user?.sub),
    {
      onError: (error) => toast.error(error.message, 'Failed To Fetch Wish List')
    }
  );

  if (loading) return <EventCardAnimation />;

  return (
    <div>
      {data?.wishList?.length ? (
        <Box pad={'small'} responsive direction="row" wrap >
          {data.wishList.map((res) => (
            <EventCard event={res.event} />
          ))}
        </Box>
      ) : (
        <EmptyEvents />
      )}
      {renderToast()}
    </div>
  );
};

const TabItems = {
  upcoming: {
    label: 'Upcoming events',
    component: Upcoming
  },
  past: {
    label: 'Past events',
    component: PastEvent
  },
  wishlist: {
    label: 'Wishlist',
    component: Wishlist
  }
};

function AccountEvents({ tab, basepath }: RouteComponentProps<{ tab: string; basepath: string }>) {
  const { user } = useAuth0();

  const getIndex = () =>
    Object.keys(TabItems).findIndex((tabName) => tab?.toLowerCase() === tabName.toLowerCase());
  const tabIndex = getIndex();

  React.useEffect(() => {
    if (typeof tab === 'undefined') {
      navigate(`/${basepath}/upcoming`);
    }
  }, [tab]);

  return (
    <UserDashboard>
      <Container>
        <div className="px-4">
          <InfoCard
            profileImage={user?.picture}
            title={user?.given_name || user?.nickname}
            subtitle={user?.email}
            variant="max"
            sublabel="Personal account"
          />
        </div>
        <Tabs activeIndex={tabIndex} className="" alignControls="start">
          {Object.keys(TabItems).map((tabKey) => {
            const { label, component: Component } = TabItems[tabKey];

            return (
              <Tab
                onClick={() => navigate(`/${basepath}/${tabKey}`)}
                className="text-gray-700 text-base font-semibold leading-[24px] m-4"
                //plain={true}
                key={tabKey}
                title={label}
              >
                <Component user={user} />
              </Tab>
            );
          })}
        </Tabs>
      </Container>
    </UserDashboard>
  );
}

AccountEvents.propTypes = {};

export default AccountEvents;
