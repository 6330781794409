import { DocumentNode, gql } from '@apollo/client';
import { detailedEventQueryResponse, standardEventQueryResponse } from 'services/hasura';

export interface IEventService {
  getEventByPeriod: (
    today: string,
    period: 'past' | 'today' | 'upcoming',
    userId: string
  ) => DocumentNode;
  getWishList: (userId: string) => DocumentNode;
  setEventStatus: (eventId: string) => DocumentNode;
  getEvent: (eventId: string, forOrganizer?: boolean) => DocumentNode;
  getEventsTickets: (eventId: string) => DocumentNode;
}

var EventService: IEventService;

EventService = class {
  static getEvent = (eventId: string, forOrganizer = false) => gql`
    query SingleEvents{
          event:events_by_pk(id: "${eventId}") {
          ${
            forOrganizer === true ? detailedEventQueryResponse(eventId) : standardEventQueryResponse
          }
        }
      }
    `;

  static getEventByPeriod = (today = Date(), period = 'upcoming', userId: string) => {
    const conditions: Record<string, string> = {
      past: '_lt',
      today: '_eq',
      upcoming: '_gt'
    };

    return gql`
    query events {
        orders(where: {user_id: {_eq:"${userId}}, created_at: {${conditions[period]}:${today}}}) {
          event {
            image_url
            name
            end_date
            event_type
            description
            address
            category
            country
            days_of_week
            deleted_at
            ticket_types{
              type,
              price
            }
          }
        }
      }
    `;
  };

  static getWishList = (userId) => gql`
  query wishlist {
    wishList:wish(where: {user_id: {_eq: "${userId}"}}) {
      event {
        name
        image:image_url
        start_date
        social
        end_date
        venues{
          venue{
            address_line1,
            address_line2
          }
        }
        organizer {
          name
        }
        ticket_types{
          type,
          price
        }
      }
    }
  }
  
  `;
  static addToWishList = gql`
    mutation AddWishList($eventId: uuid!, $userd: uuid!) {
      insert_wish_one(object: { event_id: $eventId, user_id: $userId }) {
        event {
          name
        }
      }
    }
  `;

  static setEventStatus = (eventId) => gql`
  mutation updateEventStatus($status:String!) {
    event:update_events_by_pk(pk_columns:{ id:${eventId} } _set:{ status:$status }){
      ${detailedEventQueryResponse(eventId)}
    }
  }
  `;

  static getEventsTickets = (eventId) => gql`
  query TicketTypes {
    ticket_types(order_by: {created_at: desc}, where: {ticket_group_id: {_is_null: true}, event_id: {_eq: "${eventId}"}}) {
      id
      currency
      description
      end_date
      name
      price
      questions
      sales_end
      sales_start
      start_date
      status
      type
    }
   
  }`;

  // static addTicketToEvent = gql``;
};

export default EventService;
